<template>
    <div class=" ">
        <div class="card-header bg-light "><span><i class="fa fa-file-medical"> </i> FinnishRisk</span> </div>
        <div class="card-body">
            <div class="row mt-2">     
                    <div class="col-6">Edad: {{data.edad_u}}</div>
                    <div class="col-6">Imc: {{data.imc}}</div>
                    <div class="col-6">Usa medicamentos para la presión: {{data.medicamentos_presion}}</div>
                    <div class="col-6">Antecedentes personales de diabetes: {{data.historial_glucosa}}</div>
                    <div class="col-6">Actividad fisica en horas a la semana: {{data.actividad_fisica}}</div>
                    <div class="col-6">Consumo diario de Frutas: {{data.consumo_diario_frutas}}</div>
                    <div class="col-6">Antecedentes familiares de diabetes: {{data.historial_diabetes}}</div>
                    <div class="col-6">Perimetro abdominal: {{data.perimetro_abdominal}}</div>
                    <div class="col-6">Puntaje: {{data.puntaje}}</div>
                    <div class="col-6">Riesgo: {{data.riesgo}}</div>  
            </div>
        </div>
    </div>  
</template>
<script>
export default {
    props: ['idHistoria','idUsuario','data'],
}
</script>