<template>
  <main>
    <div class="">
      <div class="card-header bg-light "><span><i class="fa fa-pills"> </i> Situaciones Personales</span> </div>
      <div class="card-body">
        <table class="table table-striped table-sm table-bordered  ">
          <tr>
            <th>Situacion</th>
            <th>Respuesta</th>
          </tr>
          <tr v-for="item in data" :key="item.id">
            <td>{{ item.situacion.situacion }}</td>
            <td>
              {{item.respuesta}}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  props: ["idHistoria", "finalizada",'data'],
  
};
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>